import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';

import config from 'src/config';

type SeoProps = {
  title?: string;
  description?: string;
  image_url?: string;
};

const Seo = ({ title, description, image_url }: SeoProps) => {
  const { t } = useTranslation();
  const seoTitle = title || t('default_title');
  const seoDescription = description || t('default_description');
  const seoImageUrl = image_url || `${config.IMAGE_CDN_HOST}/images/Logo/logo-share.jpg`;
  const router = useRouter();
  return (
    <NextSeo
      title={seoTitle}
      description={seoDescription}
      openGraph={{
        url: `${config.APP_DOMAIN}${router.asPath}`,
        title,
        description,
        images: [
          {
            url: seoImageUrl,
            width: 1200,
            height: 630,
            alt: 'skilllane logo',
          },
        ],
        site_name: 'SkillLane',
        locale: 'th_TH',
      }}
      twitter={{
        cardType: 'summary_large_image',
      }}
    />
  );
};

export default Seo;
