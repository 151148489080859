import Image from 'next/image';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { NextImage } from '../image';

import LoadingStyle, { LoadingPageStyle } from './style';

import { getAssetUrl } from 'src/helpers/utils';
import appActions from 'src/redux/app/actions';

const { set_is_loading_first_page } = appActions;

const Loading = () => (
  <LoadingStyle>
    <CircularProgress size={100} />
  </LoadingStyle>
);

export const LoadingInfiniteScroll = () => (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
    <Image width={100} height={100} src={getAssetUrl('loading-infinite-scroll.png')} alt="loading..." />
  </div>
);

export const LoadingPage = () => {
  const dispatch = useDispatch();
  return (
    <LoadingPageStyle>
      <NextImage
        onLoad={() => {
          setTimeout(() => {
            dispatch(set_is_loading_first_page(false));
          }, 2000);
        }}
        width={436}
        height={150}
        src={getAssetUrl('loading-page.png')}
        alt="loading..."
      />
    </LoadingPageStyle>
  );
};

export default Loading;
