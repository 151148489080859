import styled from '@emotion/styled';

const LoadingStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoadingPageStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: white;
  z-index: 1301;
`;

export default LoadingStyle;
