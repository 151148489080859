import styled from '@emotion/styled';

export default styled.div<any>`
  ${(props) => props.isHeight && 'height: 100%;'}
  .no-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
  .height-auto {
    width: 100%;
    height: auto;
  }
  .hidden-image {
    display: none;
  }
  span {
    font-weight: bold;
    color: #ccc;
  }
`;
